
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheLimitNumberInput',
  props: {
    value: [String, Number],
  },
  emits: ['update:value'],
  setup (props, ctx) {
    const model = shallowRef(props.value)

    const update = (e: InputEvent) => {
      const inputValue = (e.target as HTMLInputElement).value.trim()
      const fixedValue = Number(inputValue).toFixed(2)
      const numberValue = Number(fixedValue)
      model.value = numberValue || ''
      ctx.emit('update:value', String(numberValue))
    }

    return {
      model,
      update,
    }
  },
})
