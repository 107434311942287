/**
* @author 贝才[beica1@outook.com]
* @date 2020/12/22
* @description
*   TheWithdrawResult.vue of FastTradeV3
*/
<template>
  <DialogTemplate>
    <template #title>
      <t path="withdrawal_32">Withdrawal Submitted</t>
    </template>
    <div class="flex-1 t-center my-24">
      <p class="text-center">
        <Icon name="check" class="bg-success mb-20" style="font-size: 40px;color: white;border-radius: 20px" />
      </p>
      <money as="p" :v="fee" #="{value: feeV}" class="margin-top-16 primary t-center">
        <money :v="amount" #="{value}">
          <t path="withdrawal_33" :args="{fee: feeV, currency: '$', amount: value}">
            Excluding the commission of
            <money :v="fee" class="f-bold" />
            ,
            estimated actual arrival amount will be around
            <money :v="amount" class="f-bold" />
            ,
            it will take a few hours, please be patient.
          </t>
        </money>
      </money>
    </div>
    <template #controls>
      <Button class="block f-bold f-lg primary" @click="$emit('close')">
        <t path="withdrawal_34" #="{td}" custom>{{td || 'OK'}}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import Money from '@/components.global/Money'

export default {
  name: 'TheWithdrawResult',
  components: { Money, Button, Icon, DialogTemplate },
  props: {
    fee: Number,
    amount: Number,
  },
}
</script>
