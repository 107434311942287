/**
* @author zjc[beica1@outook.com]
* @date 2021/9/14 10:49
* @description
*   BindBankAccount.tw.vue of WeTrade
*/

<template>
  <DialogTemplate>
    <template #title>
      <t path="withdrawal_88">Add Bank account</t>
    </template>
    <div class="flex-1">
      <p class="c-title f-md mt-32">
        <t path="withdrawal_89" :args="{name}">Payee's name: {{name}}</t>
      </p>
      <div class="mt-8 mb-24">
        <div class="form-item block">
          <t path="withdrawal_90" custom #="{td}">
            <input class="input" type="text" v-model="account"
                   :placeholder=" td || 'CPF'" />
          </t>
        </div>
      </div>
    </div>
    <template #confirm>
      <Button class="f-bold f-lg primary flex-1" @click="submit" :progress="progress">
        <t path="withdrawal_92" #="{td}" custom>{{td || 'Submit'}}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import {
  bindFA,
  FAAccountType,
} from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindBankAccountOfBRA',
  components: { Button, DialogTemplate },
  props: {
    name: String,
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const account = shallowRef('')

    return {
      account,
      submit () {
        commit({
          type: FAAccountType.BANK_OF_BRA,
          bankAccount: account.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
    }
  },
})
</script>
