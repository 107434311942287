
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { unbindFA } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'
import * as R from 'ramda'

export default defineComponent(
  {
    name: 'TheRemoveConfirmDialog',
    components: { Button, DialogTemplate },
    props: {
      bind: Object,
    },
    setup (props, ctx) {
      const [commit, progress] = useRequest(unbindFA)
      const method = ['bank', 'UPI']
      const methodIndex = R.indexOf(props.bind?.showName, method)
      return {
        progress,
        remove () {
          commit().then(() => {
            ctx.emit('removed')
            ctx.emit('close')
          })
        },
        methodIndex,
      }
    },
  })
