/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   Out.vue of WeTrade
*/
<template>
<PageWithHeader header-class="">
  <template #title>
    <t path="trade_11">Withdrawal</t>
  </template>
  <template #right v-if="config && config.hasVerified">
    <router-link class="c-title" :to="`/out/verify${verifyDest()}?url=${config.id}`">
      <t path="deposit_11" class="f-xs mr-6 c-title">POI</t>
      <Pic src="modify_grey" width="16" height="16" class="d-ib" />
    </router-link>
  </template>
  <header class="header df-middle df-col py-24">
    <t path="withdrawal_2" class="c-title f-sm">Margin Available</t>
    <div class="mt-6 c-text">
      <TheAsset #="{ free }">
        <money :v="free" #="{symbol, value}" class="f-xl c-text df-middle">
          {{symbol}}
          <span class="f-bold" style="font-size: 31px;">{{value}}</span>
        </money>
      </TheAsset>
    </div>
  </header>
  <div class="tips f-sm py-8 px-16 df-middle" v-if="showBonus">
    <money as="p" :v="500" #="{value}" class="flex-1 mr-6">
      <t path="withdrawal_43" :args="{bonus: value}">
        First deposit bonus ${{value}} deducted
      </t>
    </money>
    <router-link to="/" class="df-middle">
      <t path="withdrawal_44" class="mr-4">Terms of event</t>
      <Icon name="warn" sm />
    </router-link>
  </div>
  <div class="px-16 f-md">
    <t class="c-text f-nm f-bold" path="withdrawal_3">Withdrawal Amount (US Dollar)</t>
    <div class="df-middle py-8 mt-8">
      <span style="font-size: 32px">$</span>
      <div class="flex-1">
        <TheLimitNumberInput v-model:value="requestAmount" />
        <!--<input v-model="requestAmount" class="flex-1" type="number" style="font-size: 32px;border: none;width: 100%">-->
      </div>
    </div>
    <Line />
    <ExchangeRate
      kind="out" :type="channel" class="c-tip mt-16" :factor="amountWithFactor"
    />
    <HoldOn :content="config" style="margin: 40px 0">
      <t path="withdrawal_6" class="c-text f-nm f-bold">Withdrawal Method</t>
      <TheBind v-if="config.hasBind" :bind="config.bind" @removed="refresh" class="mt-24" />
      <TheChannels v-else :channels="config.channels" v-model:channel="channel" />
    </HoldOn>
    <Button class="block primary f-bold f-lg br-lg" @click="process">
      <t path="withdrawal_7" #="{td}" custom>{{td || 'Withdraw funds'}}</t>
    </Button>
    <div v-once class="rules my-20 c-tip">
      <t as="div" :path="tipsI18n" multiline #="{td}"
        custom>
        <p style="line-height: 20px;" v-for="(p, index) in td" :key="index">{{p}}</p>
      </t>
    </div>
  </div>
</PageWithHeader>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { format, keymap } from '@/config'
import { isEURCountry } from '@/modules/app/app'
import TheBind from '@/modules/out/components/TheBind.vue'
import TheChannels from '@/modules/out/components/TheChannels.vue'
import TheLimitNumberInput from '@/modules/out/components/TheLimitNumberInput.vue'
import { FAAccount } from '@/modules/out/out.api'
import useTransitionIn from 'common/hooks/useTransitionIn'
import Button from '@/components/Button.vue'
import Line from '@/components/Line.vue'
import { BindState, readBindState, useWithdraw } from '@/modules/out/out'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import HoldOn from '@/provider/HoldOn.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import ExchangeRate from '@/views/ExchangeRate.vue'
import { localGet } from 'essential/store/localStore'
import { mul } from 'essential/tools/math'
import { computed, defineComponent, shallowRef } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'Out',
  components: {
    Pic,
    TheLimitNumberInput,
    TheBind,
    TheChannels,
    HoldOn,
    Button,
    ExchangeRate,
    Line,
    TheAsset,
    PageWithHeader,
    Icon,
  },
  setup () {
    const config = shallowRef<BindState | null>(null)
    const channel = shallowRef(0)
    const requestAmount = shallowRef('')

    const amountWithFactor = computed(() => mul(requestAmount.value || 0, format.moneyFactor))

    const refresh = async () => {
      config.value = await readBindState()
      if (config.value.hasBind) {
        channel.value = (config.value.bind as FAAccount).type
      }
    }

    useTransitionIn(refresh)

    const verifyDest = () => localGet(keymap.user.countryCode) === '63' ? '/ph' : ''

    const process = useWithdraw(() => {
      refresh()
    })

    const isHongKong = localGet(keymap.user.countryCode) === '852'

    const tipsI18n = isEURCountry() ? 'withdrawal_80' : (isHongKong ? 'withdrawal_101' : 'withdrawal_8')

    return {
      config,
      channel,
      requestAmount,
      amountWithFactor,
      refresh,
      process: () => process(config.value, Number(amountWithFactor.value), channel.value),
      showBonus: false,
      isEURCountry,
      verifyDest,
      tipsI18n,
    }
  },
})
</script>

<style scoped>
::v-deep(.out_header) {
  color: var(--color-white);
  background: var(--color-primary);
}

.header {
  color: var(--color-grey);
  height: 72px;
}

.number {
  color: var(--color-white);
}

.tips {
  color: var(--color-asst);
  background: #FCE9D6;
}
</style>
