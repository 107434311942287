
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import Money from '@/components.global/Money'

export default {
  name: 'TheWithdrawResult',
  components: { Money, Button, Icon, DialogTemplate },
  props: {
    fee: Number,
    amount: Number,
  },
}
