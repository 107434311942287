/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/4
 * @description
 *   usePageTransition.ts of FastTradeV3
 */
import { inject, watch, Ref, nextTick } from 'vue'

// const print = log.badge('transition', 'red')

/**
 * @todo 感觉这里会有重复调用的漏洞
 * @param onTransitionEnd
 * @param provideKey
 */
const useTransitionIn = (onTransitionEnd: Noop, provideKey = '_transition_in_state_') => {
  const ready = inject(provideKey) as Ref<boolean>

  // 若不存在provide则直接回调
  if (!ready) {
    // print('interrupt')
    onTransitionEnd()
    return
  }

  // 如果页面内部无路由切换渲染则下一帧回调
  if (ready?.value) {
    nextTick().then(() => {
      if (ready.value) {
        // print('nextTick')
        onTransitionEnd()
      }
    })
  }

  // 页面过渡动画结束后执行回调
  watch(ready, (state, preState) => {
    if (!preState && state) {
      // print('ready')
      onTransitionEnd()
    }
  })
}

export default useTransitionIn
