
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import {
  Bank,
  bindFA,
  FAAccountType,
  readBankList,
} from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindBankAccountOfTW',
  components: { Button, DialogTemplate },
  props: {
    name: String,
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const bankList = shallowRef<Bank[]>([])
    const bank = shallowRef('')
    const account = shallowRef('')
    const branchName = shallowRef('')
    const branchDigit = shallowRef('')

    readBankList({
      countryCode: 886,
    }).then(resp => bankList.value = resp)

    // const submit = bindBank(next)
    return {
      bankList,
      bank,
      account,
      branchName,
      branchDigit,
      submit () {
        commit({
          type: FAAccountType.BANK_OF_TW,
          bankId: bank.value,
          bankAccount: account.value,
          branchName: branchName.value,
          branchDigit: branchDigit.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
    }
  },
})
