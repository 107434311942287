
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { openDialog as showDialog } from '@/components/popup/popup'
import TheRemoveConfirmDialog from './TheRemoveConfirmDialog.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheBind',
  components: { Icon, Pic },
  props: {
    bind: {
      type: Object,
      required: true,
    },
  },
  emits: ['removed'],
  setup (props, ctx) {
    const unbind = () => {
      showDialog(
        TheRemoveConfirmDialog,
        {
          bind: props.bind,
          onRemoved: () => ctx.emit('removed'),
        },
      )
    }
    return {
      account: props.bind.account,
      unbind,
    }
  },
})
