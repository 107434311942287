
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, FAAccountType, Bank, readBankList } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindBankAccountOfMA',
  components: { Button, DialogTemplate },
  props: {
    name: String,
    channel: Number,
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const bankId = shallowRef('')
    const areaId = shallowRef('')
    const account = shallowRef('')
    const branchName = shallowRef('')
    const bankList = shallowRef<Array<Bank>>([])
    const city = shallowRef('')

    const areaList = [
      { name: 'Johor', id: 43 },
      { name: 'Kedah', id: 44 },
      { name: 'Kelantan', id: 45 },
      { name: 'Melaka', id: 46 },
      { name: 'Negeri Sembilan', id: 47 },
      { name: 'Pahang', id: 48 },
      { name: 'Perak', id: 49 },
      { name: 'Perlis', id: 50 },
      { name: 'Pulau Pinang', id: 51 },
      { name: 'Sabah', id: 52 },
      { name: 'Sarawak', id: 53 },
      { name: 'Selangor', id: 54 },
      { name: 'Terengganu', id: 55 },
      { name: 'Wilayah Persekutuan Kuala Lumpur', id: 56 },
      { name: 'Wilayah Persekutuan Labuan', id: 57 },
      { name: 'Wilayah Persekutuan Putrajaya', id: 58 },
    ]

    readBankList({
      countryCode: 60,
    }).then(resp => {
      bankList.value = resp
    })

    return {
      bankList,
      bankId,
      areaId,
      account,
      branchName,
      city,
      submit () {
        commit({
          type: FAAccountType.BANK_OF_MALA,
          bankId: bankId.value,
          bankAccount: account.value,
          branchName: branchName.value,
          city: city.value,
          regionId: areaId.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
      areaList,
    }
  },
})
