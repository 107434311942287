/**
* @author 贝才[beica1@outook.com]
* @date 2020/12/4
* @description
*   TheBind.vue of FastTradeV3
*/
<template>
  <div class="df-middle">
    <div class="flex-1 df-middle">
      <Pic :src="bind.icon" width="72" height="24" />
      <span class="mx-8 c-text f-nm f-bold">{{bind.showName}}</span>
      {{account}}
    </div>
    <button class="cancel" @click="unbind">
      <Icon name="remove" sm class="mr-4" />
      <t path="managewatchlist_3" class="d-ib ml-4">Remove</t>
    </button>
  </div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { openDialog as showDialog } from '@/components/popup/popup'
import TheRemoveConfirmDialog from './TheRemoveConfirmDialog.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheBind',
  components: { Icon, Pic },
  props: {
    bind: {
      type: Object,
      required: true,
    },
  },
  emits: ['removed'],
  setup (props, ctx) {
    const unbind = () => {
      showDialog(
        TheRemoveConfirmDialog,
        {
          bind: props.bind,
          onRemoved: () => ctx.emit('removed'),
        },
      )
    }
    return {
      account: props.bind.account,
      unbind,
    }
  },
})
</script>

<style scoped>
.cancel {
  min-width: 92px;
  height: 32px;
  border: none;
  border-radius: 16px;
  color: var(--color-title);
  background: var(--color-light);
}
</style>
