/**
* @author zjc[beica1@outook.com]
* @date 2021/11/8 17:16
* @description
*   BindBankAccount.eur.vue of WeTrade
*/

<template>
  <DialogTemplate>
    <template #title>
      <t path="withdrawal_35">Add Bank account</t>
    </template>
    <div class="flex-1">
      <p class="c-title f-md mt-32">
        <t path="withdrawal_36" :args="{name}">Payee's name: {{name}}</t>
      </p>
      <div class="mt-8 mb-24">
        <div class="form-item block">
          <t path="withdrawal_75" custom #="{td}">
            <input class="input" type="text" v-model="bic" :placeholder=" td || 'BIC code'" />
          </t>
        </div>
        <div class="form-item block">
          <t path="withdrawal_76" custom #="{td}">
            <input class="input" type="text" v-model="account"
              :placeholder=" td || 'IBAN number'" />
          </t>
        </div>
        <div class="form-item block">
          <t path="withdrawal_77" custom #="{td}">
            <textarea class="input" type="text" v-model="address"
              :placeholder=" td || 'Residential address'"
              style="min-height: 80px;resize: none;" />
          </t>
        </div>
      </div>
    </div>
    <template #confirm>
      <Button class="f-bold f-lg primary flex-1" @click="submit" :progress="progress">
        <t path="withdrawal_14" #="{td}" custom>{{td || 'Submit'}}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, FAAccountType } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindBankAccountOfEUR',
  components: { Button, DialogTemplate },
  props: {
    name: String,
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const bic = shallowRef('')
    const account = shallowRef('')
    const address = shallowRef('')

    // const submit = bindBank(next)
    return {
      bic,
      account,
      address,
      submit () {
        commit({
          type: FAAccountType.BANK_OF_EUR,
          bicCode: bic.value,
          bankAccount: account.value,
          address: address.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
    }
  },
})
</script>
