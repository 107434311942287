
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, FAAccountType } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindBankAccountOfJP',
  components: { Button, DialogTemplate },
  props: {
    name: String,
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const bank = shallowRef('')
    const account = shallowRef('')
    const branch = shallowRef('')

    // const submit = bindBank(next)
    return {
      bank,
      account,
      branch,
      submit () {
        commit({
          type: FAAccountType.BANK_OF_JP,
          bank: bank.value,
          bankAccount: account.value,
          branchName: branch.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
    }
  },
})
