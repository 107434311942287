
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, readNetellerAccount, FAAccountType } from '@/modules/out/out.api'
import LoadingOr from '@/provider/LoadingOr.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, onMounted, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindNetellerAccount',
  components: { LoadingOr, Button, DialogTemplate },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const account = shallowRef('')
    const [read, loading] = useRequest(readNetellerAccount)

    onMounted(() => {
      read().then((resp) => {
        account.value = resp.email
      })
    })

    return {
      account,
      submit () {
        commit({
          type: FAAccountType.NETELLER,
          gcashAccount: account.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
      loading,
    }
  },
})
