/**
* @author 贝才[beica1@outook.com]
* @date 2020/12/15
* @description
*   TheRemoveConfirmDialog.vue of FastTradeV3
*/
<template>
  <DialogTemplate>
    <template #title>
      <!--   Remove {{bind.showName}} Account   -->
      <t :path="['withdrawal_20', 'withdrawal_24'][methodIndex]">Remove {{bind.showName}} Account</t>
    </template>
    <div class="flex-1 p-16 t-center" style="margin-bottom: 48px;border-radius: 8px">
      <t as="p" class="mt-12 dark"
        :path="['withdrawal_21', 'withdrawal_25'][methodIndex]" :args="{account: bind.account}">
        Confirm to remove account <span class="f-bold">{{bind.account}}</span>?
      </t>
    </div>
    <template #confirm>
      <Button class="primary flex-1" @click="remove" :progress="progress">
        <t path="withdrawal_23" #="{td}" custom>{{ td || 'Confirm' }}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { unbindFA } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'
import * as R from 'ramda'

export default defineComponent(
  {
    name: 'TheRemoveConfirmDialog',
    components: { Button, DialogTemplate },
    props: {
      bind: Object,
    },
    setup (props, ctx) {
      const [commit, progress] = useRequest(unbindFA)
      const method = ['bank', 'UPI']
      const methodIndex = R.indexOf(props.bind?.showName, method)
      return {
        progress,
        remove () {
          commit().then(() => {
            ctx.emit('removed')
            ctx.emit('close')
          })
        },
        methodIndex,
      }
    },
  })
</script>

<style scoped>

</style>
