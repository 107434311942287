<template>
  <DialogTemplate>
    <template #title>
      <t path="withdrawal_35">Add Bank account</t>
    </template>
    <div class="flex-1">
      <p class="c-title f-md mt-32">
        <t path="withdrawal_36" :args="{name}">Payee's name: {{name}}</t>
      </p>
      <div class="mt-8 mb-24">
        <div class="form-item block pr-8 p-r">
          <div class="ml-8 mt-10 c-tip spread disabled" v-if="!bank">
            <t path="withdrawal_37"></t>
          </div>
          <select v-model="bank" class="input">
            <option v-for="bank in bankList" :key="bank.bankId" :value="bank.bankId">
              {{bank.bankName}}
            </option>
          </select>
        </div>
        <div class="form-item block">
          <t path="withdrawal_38" custom #="{td}">
            <input class="input" type="text" v-model="account"
                   :placeholder=" td || 'Bank Account Number'" />
          </t>
        </div>

      </div>
    </div>
    <template #confirm>
      <Button class="f-bold f-lg primary flex-1" @click="submit" :progress="progress">
        <t path="withdrawal_14" #="{td}" custom>{{td || 'Submit'}}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import {
  Bank,
  bindFA,
  FAAccountType,
  readBankList,
} from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindBankAccountOfHK',
  components: { Button, DialogTemplate },
  props: {
    name: String,
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const bankList = shallowRef<Bank[]>([])
    const bank = shallowRef('')
    const account = shallowRef('')

    readBankList({
      countryCode: 852,
    }).then(resp => bankList.value = resp)

    // const submit = bindBank(next)
    return {
      bankList,
      bank,
      account,
      submit () {
        commit({
          type: FAAccountType.BANK_OF_HK,
          bankId: bank.value,
          bankAccount: account.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
    }
  },
})
</script>
