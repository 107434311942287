/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/16
* @description
*   BindBankAccount.vi.vue of WeTrade
*/

<template>
  <DialogTemplate>
    <template #title>
      <t path="withdrawal_35">Add Bank account</t>
    </template>
    <div class="flex-1">
      <p class="c-title f-md mt-32">
        <t path="withdrawal_36" :args="{name}">Payee's name: {{name}}</t>
      </p>
      <div class="mt-8 mb-24">
        <div class="form-item block pr-8 p-r">
          <div class="ml-8 mt-10 c-tip spread disabled" v-if="!bankId">
            <t path="withdrawal_37"></t>
          </div>
          <select v-model="bankId" class="input">
            <option v-for="bank in bankList" :key="bank.bankId" :value="bank.bankId">
              {{bank.bankName}}
            </option>
          </select>
        </div>
        <div class="form-item block">
          <t path="withdrawal_38" custom #="{td}">
            <input class="input" type="text" v-model="account"
              :placeholder=" td || 'Bank Account Number'" />
          </t>
        </div>
        <div class="form-item block">
          <t path="withdrawal_39" custom #="{td}">
            <input class="input" type="text" v-model="branchName"
              :placeholder=" td || 'Branch name (optional)'" />
          </t>
        </div>
        <t as="p" path="withdrawal_40" class="c-tip f-md mt-8">
          If there is no branch name, you can fill in "others"
        </t>
      </div>
    </div>
    <template #confirm>
      <Button class="f-bold f-lg primary flex-1" @click="submit" :progress="progress">
        <t path="withdrawal_14" #="{td}" custom>{{td || 'Submit'}}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, FAAccountType, Bank, readBankList } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindBankAccountOfVI',
  components: { Button, DialogTemplate },
  props: {
    name: String,
    channel: Number,
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const bankId = shallowRef('')
    const account = shallowRef('')
    const branchName = shallowRef('')
    const bankList = shallowRef<Array<Bank>>([])

    readBankList().then(resp => {
      bankList.value = resp
    })

    // const submit = bindBank(next)
    return {
      bankList,
      bankId,
      account,
      branchName,
      submit () {
        commit({
          type: props.channel ?? FAAccountType.BANK_OF_VI,
          bankId: bankId.value,
          bankAccount: account.value,
          branchName: branchName.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
    }
  },
})
</script>
