/**
* File: BindNetellerAccount.vue of WeTrade
* Author: 阿佑[ayooooo@petalmail.com]
* Date: 2023/9/8 11:13
*/
<template>
  <DialogTemplate>
    <template #title>
      <t path="withdrawal_111">Add Neteller account</t>
    </template>
    <div class="flex-1" :class="loading ? 't-center' : ''">
      <LoadingOr :loading="loading">
        <template v-if="account">
          <p class="c-title f-md mt-32">
            <t path="withdrawal_112">Your last Neteller deposit account</t>
          </p>
          <div class="mt-8 mb-24">
            <div class="form-item block">
              <t path="withdrawal_112" custom #="{td}">
                <input class="input" type="text" :value="account"
                       :placeholder=" td || 'Neteller Account'" readonly />
              </t>
            </div>
          </div>
        </template>
        <p v-else>
          <t pat="withdrawal_115">
            Only after depositing funds via Neteller can you withdraw money using Neteller.
          </t>
        </p>
      </LoadingOr>
    </div>

    <template v-if="!account" #cancel>
      <Button class="f-bold f-lg primary flex-1" @click="$emit('close')">
        <t path="withdrawal_116" #="{td}" custom>{{ td || 'OK' }}</t>
      </Button>
    </template>

    <template #confirm>
      <Button v-if="account" class="f-bold f-lg primary flex-1" @click="submit" :progress="progress">
        <t path="withdrawal_114" #="{td}" custom>{{ td || 'Submit' }}</t>
      </Button>
      <span v-else></span>
    </template>
  </DialogTemplate>
</template>
<script lang="ts">
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, readNetellerAccount, FAAccountType } from '@/modules/out/out.api'
import LoadingOr from '@/provider/LoadingOr.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, onMounted, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindNetellerAccount',
  components: { LoadingOr, Button, DialogTemplate },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const account = shallowRef('')
    const [read, loading] = useRequest(readNetellerAccount)

    onMounted(() => {
      read().then((resp) => {
        account.value = resp.email
      })
    })

    return {
      account,
      submit () {
        commit({
          type: FAAccountType.NETELLER,
          gcashAccount: account.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
      loading,
    }
  },
})
</script>
