
import DialogTemplate from '@/views/DialogTemplate.vue'

export default {
  name: 'TheConfirmDialog',
  components: { DialogTemplate },
  props: {
    amount: Number,
  },
}
