/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/28 16:08
* @description
*   LoadingOr.vue of WeTrade
*/
<template>
<Donut v-if="loading" />
<slot v-else />
</template>

<script lang="ts">
import Donut from 'common/loading/Donut.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingOr',
  components: { Donut },
  props: {
    loading: Boolean,
  },
})
</script>
