/**
*  @file         src/modules/out/BindWalletAddress.vue created by WebStorm
*  @project      WeTradeH5
*  @author       阿佑[ayooooo@petalmail.com]
*  @date         2022/9/21 16:56
*  @description
*/
<template>
<DialogTemplate>
  <template #title><t path="withdrawal_93">Add Crypto Address</t></template>
  <div class="flex-1">
    <div class="mt-8 mb-24">
      <div class="form-item block pr-8 p-r">
        <div class="ml-8 mt-10 c-tip spread disabled" v-if="!bank">
          <t path="withdrawal_84"></t>
        </div>
        <select v-model="bank" class="input" @change="change">
          <option v-for="bank in bankList" :key="bank.bankId" :value="bank.bankId">
            {{bank.bankName}}
          </option>
        </select>
      </div>
      <div class="form-item block">
        <t :path="placeholder" custom #="{td}">
          <input class="input" type="text" v-model="account" :placeholder="td || 'TRC20'" />
        </t>
      </div>
    </div>
  </div>
  <template #confirm>
    <Button class="f-bold f-lg primary flex-1" @click="submit" :progress="progress">
      <t path="withdrawal_14" #="{td}" custom>{{ td || 'Submit' }}</t>
    </Button>
  </template>
</DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, FAAccountType } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindWalletAddress',
  components: { Button, DialogTemplate },
  props: {},
  setup (props, ctx) {
    const bankList = [{
      bankId: 'USDT',
      bankName: 'USDT',
      path: 'withdrawal_94',
    }, {
      bankId: 'BTC',
      bankName: 'Bitcoin',
      path: 'withdrawal_95',
    }, {
      bankId: 'ETH',
      bankName: 'Ethereum',
      path: 'withdrawal_96',
    }]

    const bank = shallowRef(bankList[0].bankId)
    const placeholder = shallowRef(bankList[0].path)
    const account = shallowRef('')
    const [commit, progress] = useRequest(bindFA)

    return {
      bankList,
      bank,
      placeholder,
      account,
      progress,
      submit () {
        commit({
          type: FAAccountType.CRYPTOCURRENCIES,
          bank: bank.value,
          bankAccount: account.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      change () {
        const selected = bankList.find(b => b.bankId === bank.value)
        if (selected) {
          placeholder.value = selected.path
        }
      },
    }
  },
})
</script>
