<template>
  <DialogTemplate>
    <template #title>
      <t path="withdrawal_122">Add Paypal account</t>
    </template>
    <div class="flex-1">
      <p class="c-title f-md mt-32">
        <t path="withdrawal_123" :args="{name}">Payee's name: {{name}}</t>
      </p>
      <div class="mt-8 mb-24">
        <div class="form-item block">
          <t path="withdrawal_124" custom #="{td}">
            <input class="input" type="text" v-model="account"
                   :placeholder=" td || 'PayPal’s registered email'" />
          </t>
        </div>
      </div>
    </div>
    <template #confirm>
      <Button class="f-bold f-lg primary flex-1" @click="submit" :progress="progress">
        <t path="withdrawal_126" #="{td}" custom>{{td || 'Submit'}}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import {
  bindFA,
  FAAccountType,
} from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindPaypalAccount',
  components: { Button, DialogTemplate },
  props: {
    name: String,
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const account = shallowRef('')

    return {
      account,
      submit () {
        commit({
          type: FAAccountType.GERMANY,
          gcashAccount: account.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
    }
  },
})
</script>
