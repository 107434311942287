
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, FAAccountType } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindBinanceAccount',
  components: { Button, DialogTemplate },
  props: {
    name: String,
  },
  setup (_, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const account = shallowRef('')

    return {
      account,
      submit () {
        commit({
          type: FAAccountType.BINANCE,
          gcashAccount: account.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
    }
  },
})
