<template>
  <DialogTemplate>
    <template #title>
      <t path="withdrawal_35">Add Bank account</t>
    </template>
    <div class="flex-1">
      <p class="c-title f-md mt-32">
        <t path="withdrawal_36" :args="{name}">Payee's name: {{name}}</t>
      </p>
      <div class="mt-8 mb-24">
        <div class="form-item block pr-8 p-r">
          <div class="ml-8 mt-10 c-tip spread disabled" v-if="!bankId">
            <t path="withdrawal_37"></t>
          </div>
          <select v-model="bankId" class="input">
            <option v-for="bank in bankList" :key="bank.bankId" :value="bank.bankId">
              {{bank.bankName}}
            </option>
          </select>
        </div>

        <div class="form-item block">
          <t path="withdrawal_39" custom #="{td}">
            <input class="input" type="text" v-model="branchName"
              :placeholder=" td || 'Branch name (optional)'" />
          </t>
        </div>

        <div class="form-item block">
          <t path="withdrawal_38" custom #="{td}">
            <input class="input" type="text" v-model="account"
              :placeholder=" td || 'Bank Account Number'" />
          </t>
        </div>

        <div class="form-item block pr-8 p-r">
          <div class="ml-8 mt-10 c-tip spread disabled" v-if="!areaId">
            <t path="">Area of Residence</t>
          </div>
          <select v-model="areaId" class="input">
            <option v-for="area in areaList" :key="area.id" :value="area.id">
              {{area.name}}
            </option>
          </select>
        </div>

        <div class="form-item block">
          <t path="" custom #="{td}">
            <input class="input" type="text" v-model="city"
              :placeholder=" td || 'City of Residence'" />
          </t>
        </div>

      </div>
    </div>
    <template #confirm>
      <Button class="f-bold f-lg primary flex-1" @click="submit" :progress="progress">
        <t path="withdrawal_14" #="{td}" custom>{{td || 'Submit'}}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, FAAccountType, Bank, readBankList } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindBankAccountOfMA',
  components: { Button, DialogTemplate },
  props: {
    name: String,
    channel: Number,
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const bankId = shallowRef('')
    const areaId = shallowRef('')
    const account = shallowRef('')
    const branchName = shallowRef('')
    const bankList = shallowRef<Array<Bank>>([])
    const city = shallowRef('')

    const areaList = [
      { name: 'Johor', id: 43 },
      { name: 'Kedah', id: 44 },
      { name: 'Kelantan', id: 45 },
      { name: 'Melaka', id: 46 },
      { name: 'Negeri Sembilan', id: 47 },
      { name: 'Pahang', id: 48 },
      { name: 'Perak', id: 49 },
      { name: 'Perlis', id: 50 },
      { name: 'Pulau Pinang', id: 51 },
      { name: 'Sabah', id: 52 },
      { name: 'Sarawak', id: 53 },
      { name: 'Selangor', id: 54 },
      { name: 'Terengganu', id: 55 },
      { name: 'Wilayah Persekutuan Kuala Lumpur', id: 56 },
      { name: 'Wilayah Persekutuan Labuan', id: 57 },
      { name: 'Wilayah Persekutuan Putrajaya', id: 58 },
    ]

    readBankList({
      countryCode: 60,
    }).then(resp => {
      bankList.value = resp
    })

    return {
      bankList,
      bankId,
      areaId,
      account,
      branchName,
      city,
      submit () {
        commit({
          type: FAAccountType.BANK_OF_MALA,
          bankId: bankId.value,
          bankAccount: account.value,
          branchName: branchName.value,
          city: city.value,
          regionId: areaId.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
      areaList,
    }
  },
})
</script>
