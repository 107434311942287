
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, FAAccountType } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindWalletAddress',
  components: { Button, DialogTemplate },
  props: {},
  setup (props, ctx) {
    const bankList = [{
      bankId: 'USDT',
      bankName: 'USDT',
      path: 'withdrawal_94',
    }, {
      bankId: 'BTC',
      bankName: 'Bitcoin',
      path: 'withdrawal_95',
    }, {
      bankId: 'ETH',
      bankName: 'Ethereum',
      path: 'withdrawal_96',
    }]

    const bank = shallowRef(bankList[0].bankId)
    const placeholder = shallowRef(bankList[0].path)
    const account = shallowRef('')
    const [commit, progress] = useRequest(bindFA)

    return {
      bankList,
      bank,
      placeholder,
      account,
      progress,
      submit () {
        commit({
          type: FAAccountType.CRYPTOCURRENCIES,
          bank: bank.value,
          bankAccount: account.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      change () {
        const selected = bankList.find(b => b.bankId === bank.value)
        if (selected) {
          placeholder.value = selected.path
        }
      },
    }
  },
})
