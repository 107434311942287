/**
* @author 贝才[beica1@outook.com]
* @date 2020/12/22
* @description
*   TheConfirmDialog.vue of FastTradeV3
*/
<template>
  <DialogTemplate>
    <template #title>
      <p class="text-center primary"><t path="withdrawal_28">Confirm Withdrawal</t></p>
    </template>
    <money as="div" :v="amount" #="{value}" class="p-16 mb-24 flex-1 t-center">
      <t path="withdrawal_29" :args="{amount: value}">
        The withdrawal amount is
        <money :v="amount" class="c-primary f-bold" />
      </t>
    </money>
  </DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'

export default {
  name: 'TheConfirmDialog',
  components: { DialogTemplate },
  props: {
    amount: Number,
  },
}
</script>
