/**
* File: BindSkrillAccount.vue of WeTrade
* Author: 阿佑[ayooooo@petalmail.com]
* Date: 2023/9/8 10:48
*/
<template>
  <DialogTemplate>
    <template #title>
      <t path="withdrawal_107">Add Skrill account</t>
    </template>
    <div class="flex-1">
      <div class="mt-8 mb-24">
        <div class="form-item block">
          <t path="withdrawal_108" custom #="{td}">
            <input class="input" type="text" v-model="account"
                   :placeholder=" td || 'Biance Pay ID'" />
          </t>
        </div>
      </div>
    </div>
    <template #confirm>
      <Button class="f-bold f-lg primary flex-1" @click="submit" :progress="progress">
        <t path="withdrawal_110" #="{td}" custom>{{ td || 'Submit' }}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, FAAccountType } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindSkrillAccount',
  components: { DialogTemplate, Button },
  setup (_, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const account = shallowRef('')

    return {
      account,
      submit () {
        commit({
          type: FAAccountType.SKRILL,
          gcashAccount: account.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
    }
  },
})
</script>
