/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/16
 * @description
 *   out.ts of WeTrade
 */
import { openDialog, openDialog as showDialog, showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'
import BindBankAccountOfBRA from '@/modules/out/BindBankAccount.bra.vue'
import BindBankAccountOfEUR from '@/modules/out/BindBankAccount.eur.vue'
import BindBankAccountOfHK from '@/modules/out/BindBankAccount.hk.vue'
import BindBankAccountOfJP from '@/modules/out/BindBankAccount.jp.vue'
import BindBankAccountOfMA from '@/modules/out/BindBankAccount.ma.vue'
import BindBankAccountOfPH from '@/modules/out/BindBankAccount.ph.vue'
import BindBankAccountOfTC from '@/modules/out/BindBankAccount.tc.vue'
import BindBankAccountOfTH from '@/modules/out/BindBankAccount.th.vue'
import BindBankAccountOfTW from '@/modules/out/BindBankAccount.tw.vue'
import BindBankAccountOfVi from '@/modules/out/BindBankAccount.vi.vue'
import BindBankAccount from '@/modules/out/BindBankAccount.vue'
import BindBinanceAccount from '@/modules/out/BindBinanceAccount.vue'
import BindFPSofHK from '@/modules/out/BindFPSofHK.vue'
import BindNetellerAccount from '@/modules/out/BindNetellerAccount.vue'
import BindSkrillAccount from '@/modules/out/BindSkrillAccount.vue'
import BindUPIAccount from '@/modules/out/BindUPIAccount.vue'
import BindWalletAddress from '@/modules/out/BindWalletAddress.vue'
import BindPaypalAccount from '@/modules/out/BindPaypalAccount.vue'
import TheConfirmDialog from '@/modules/out/components/TheConfirmDialog.vue'
import TheWithdrawResult from '@/modules/out/components/TheWithdrawResult.vue'
import {
  create,
  FAAccount,
  FAAccountType,
  OutChannel,
  OutRecord,
  read,
} from '@/modules/out/out.api'
import { readBindFA } from '@/modules/user/user.api'
import { pendingTask } from 'essential/task/task.temp'
import * as R from 'ramda'
import { useRoute, useRouter } from 'vue-router'

export type BindState = {
  hasBind: boolean;
  bind: null | FAAccount;
  name: null | string;
  hasVerified: boolean;
  id: string;
  channels: Array<OutChannel>;
}

/**
 * 读取出金账户信息
 */
export const readBindState = async (): Promise<BindState> => {
  const [result, channels] = await Promise.all([readBindFA(), read()])
  const hasBind = Boolean(result?.cashOutType)
  const bind = hasBind && channels ? R.find(R.propEq('type', result?.cashOutType), channels) : null
  const account = result?.cashOutType === FAAccountType.UPI
    ? result?.vpa
    : (
      result?.cashOutType === FAAccountType.BANK_OF_PH
        ? result?.gcashAccount
        : result?.bankAccount
    )

  return {
    // 是否已经绑定账号
    hasBind,
    // 已绑信息
    bind: R.assoc('account', R.slice(-4, Infinity, R.trim(account ?? '')), bind),
    name: R.propOr(null, 'name', result),
    // 是否已经通过身份认证
    hasVerified: Boolean(result?.idCardImg),
    id: result?.idCardImg ?? '',
    // 渠道列表
    channels: channels ?? [],
  }
}

const pickBindView = (channel: FAAccountType) => {
  switch (channel) {
    case FAAccountType.BANK_OF_TW:
      return BindBankAccountOfTW
    case FAAccountType.BANK_OF_JP:
      return BindBankAccountOfJP
    case FAAccountType.BANK_OF_TH:
      return BindBankAccountOfTH
    case FAAccountType.BANK_OF_VI:
    case FAAccountType.BANK_OF_ID:
      return BindBankAccountOfVi
    case FAAccountType.BANK_OF_IN:
      return BindBankAccount
    case FAAccountType.UPI:
      return BindUPIAccount
    case FAAccountType.BANK_OF_PH:
      return BindBankAccountOfPH
    case FAAccountType.BANK_OF_EUR:
      return BindBankAccountOfEUR
    case FAAccountType.BANK_OF_BRA:
      return BindBankAccountOfBRA
    case FAAccountType.BANK_OF_TC:
      return BindBankAccountOfTC
    case FAAccountType.BANK_OF_MALA:
      return BindBankAccountOfMA
    case FAAccountType.CRYPTOCURRENCIES:
      return BindWalletAddress
    case FAAccountType.BANK_OF_HK:
      return BindBankAccountOfHK
    case FAAccountType.FPS_OF_HK:
      return BindFPSofHK
    case FAAccountType.BINANCE:
      return BindBinanceAccount
    case FAAccountType.SKRILL:
      return BindSkrillAccount
    case FAAccountType.NETELLER:
      return BindNetellerAccount
    case FAAccountType.GERMANY:
      return BindPaypalAccount
  }
}

/**
 * 绑定出金账户
 * @param channel
 * @param name
 * @param onConfirm
 */
const bindFA = (channel: FAAccountType, name: string, onConfirm: () => void) => {
  const bindView = pickBindView(channel)
  if (bindView) {
    openDialog(bindView, { name, onConfirm, channel })
  } else {
    showAlert(translate('toast_2', 'Channel Error'))
  }
}

/**
 * 确认提现金额
 * @param amount
 */
const beforeCommit = (amount: number) => new Promise(resolve => showDialog(
  TheConfirmDialog,
  {
    amount,
    onConfirm: () => resolve(true),
    onCancel: () => resolve(false),
  },
))

/**
 * 发起体现请求
 * @param amount
 * @param type
 */
const commit = async (amount: number, type: number) => {
  const confirmed = await beforeCommit(amount)
  if (confirmed) return create({ type, amount })
  return Promise.reject('withdraw canceled ')
}

/**
 * 展示提现结果
 * @param request
 */
const afterCommit = (request: number) => {
  return (resp: OutRecord) => showDialog(
    TheWithdrawResult, {
      fee: resp.fee,
      amount: (request - resp.fee).toFixed(2),
    })
}

/**
 * 检查提现条件，处理提现请求
 */
export const useWithdraw = (done?: () => void) => {
  const router = useRouter()
  const route = useRoute()

  return function process (state: BindState | null, amount: number, channel: FAAccountType) {
    if (!amount) {
      showAlert(
        translate('toast_3', 'Withdrawal amount is required!'),
      )
      return
    }

    if (!state) return

    const name = state.name
    let hasBind = state.hasBind
    let hasVerified = state.hasVerified

    /**
     * 反复尝试提交
     */
    function foo () {
      // 账户绑定
      if (hasBind) {
        // 发起申请
        return commit(amount, channel).then(afterCommit(amount))
      } else {
        if (R.either(R.isNil, R.isEmpty)(name)) {
          // 基础资料不存在 跳转填写
          return router.push({ name: 'fillInBaseForm', query: { redirect: route.path } })
        } else if (!hasVerified) {
          // 账户认证
          const id: string = pendingTask(() => {
            hasVerified = true
            foo()
          })

          return router.push({
            name: channel === FAAccountType.BANK_OF_PH ? 'verifyWithID' : 'verify',
            query: {
              pendingTaskId: id,
            },
          })
        } else {
          if (!channel) {
            showAlert(translate('withdrawal_71'))
            return
          }
          // 绑定出金账户后继续下一步 因为没有跳转 因此使用回调
          return bindFA(channel, name as string, () => {
            done?.()
            hasBind = true
            foo()
          })
        }
      }
    }

    return foo()
  }
}
