/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/30
* @description
*   TheLimitNumberInput.vue of WeTrade
*/
<template>
  <input class="flex-1 bg-theme" v-model="model" type="number" style="font-size: 32px;border: none;width: 100%" @input="update">
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheLimitNumberInput',
  props: {
    value: [String, Number],
  },
  emits: ['update:value'],
  setup (props, ctx) {
    const model = shallowRef(props.value)

    const update = (e: InputEvent) => {
      const inputValue = (e.target as HTMLInputElement).value.trim()
      const fixedValue = Number(inputValue).toFixed(2)
      const numberValue = Number(fixedValue)
      model.value = numberValue || ''
      ctx.emit('update:value', String(numberValue))
    }

    return {
      model,
      update,
    }
  },
})
</script>
