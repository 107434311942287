
import Pic from '@/components/Pic.vue'
import { format, keymap } from '@/config'
import { isEURCountry } from '@/modules/app/app'
import TheBind from '@/modules/out/components/TheBind.vue'
import TheChannels from '@/modules/out/components/TheChannels.vue'
import TheLimitNumberInput from '@/modules/out/components/TheLimitNumberInput.vue'
import { FAAccount } from '@/modules/out/out.api'
import useTransitionIn from 'common/hooks/useTransitionIn'
import Button from '@/components/Button.vue'
import Line from '@/components/Line.vue'
import { BindState, readBindState, useWithdraw } from '@/modules/out/out'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import HoldOn from '@/provider/HoldOn.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import ExchangeRate from '@/views/ExchangeRate.vue'
import { localGet } from 'essential/store/localStore'
import { mul } from 'essential/tools/math'
import { computed, defineComponent, shallowRef } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'Out',
  components: {
    Pic,
    TheLimitNumberInput,
    TheBind,
    TheChannels,
    HoldOn,
    Button,
    ExchangeRate,
    Line,
    TheAsset,
    PageWithHeader,
    Icon,
  },
  setup () {
    const config = shallowRef<BindState | null>(null)
    const channel = shallowRef(0)
    const requestAmount = shallowRef('')

    const amountWithFactor = computed(() => mul(requestAmount.value || 0, format.moneyFactor))

    const refresh = async () => {
      config.value = await readBindState()
      if (config.value.hasBind) {
        channel.value = (config.value.bind as FAAccount).type
      }
    }

    useTransitionIn(refresh)

    const verifyDest = () => localGet(keymap.user.countryCode) === '63' ? '/ph' : ''

    const process = useWithdraw(() => {
      refresh()
    })

    const isHongKong = localGet(keymap.user.countryCode) === '852'

    const tipsI18n = isEURCountry() ? 'withdrawal_80' : (isHongKong ? 'withdrawal_101' : 'withdrawal_8')

    return {
      config,
      channel,
      requestAmount,
      amountWithFactor,
      refresh,
      process: () => process(config.value, Number(amountWithFactor.value), channel.value),
      showBonus: false,
      isEURCountry,
      verifyDest,
      tipsI18n,
    }
  },
})
