
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, FAAccountType, Bank, readBankList } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindBankAccountOfVI',
  components: { Button, DialogTemplate },
  props: {
    name: String,
    channel: Number,
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const bankId = shallowRef('')
    const account = shallowRef('')
    const branchName = shallowRef('')
    const bankList = shallowRef<Array<Bank>>([])

    readBankList().then(resp => {
      bankList.value = resp
    })

    // const submit = bindBank(next)
    return {
      bankList,
      bankId,
      account,
      branchName,
      submit () {
        commit({
          type: props.channel ?? FAAccountType.BANK_OF_VI,
          bankId: bankId.value,
          bankAccount: account.value,
          branchName: branchName.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
    }
  },
})
